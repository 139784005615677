/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Tooltip from '@mui/material/Tooltip';
import { useEffect, useState } from 'react';
import { ChevronDown } from 'react-feather';
import { useAppState } from '../overmind';
import { fetchLexigoLanguage } from '../requests/LanguageRequest';
import { createTranslation, fetchTranslatedLanguage } from '../requests/TranslateRequest';
import { getTotalDurationRequest } from '../requests/UtilRequest';
import DurationExceedModal from './DurationExceedModal';
import TranslateIcon from './Icon/TranslateIcon.js';
import PricingModal from './Pricing/PricingModal';
import TranslateModal from './TranslateModal';
import useComponentVisible from './useComponentVisible';

const CreateTranslationDropdown = ({
    isButton = false,
    config,
    setTranslateModal,
    setIsTranslation,
    id,
    setLoading,
    translatedLanguage,
    fetchTranslated,
    fetchTranslate,
    setPlaying,
    setTranslateState,
    setCurrentLanguageObject,
    setCurrentLanguage,
    setLanguageText,
    user,
    access,
    languages,
    setIsTranslateChange,
    setTranslationSingleSelected,
    setConfig,
    translateModal,
    pricing,
    setPricing,
    selectedLanguages,
    setSelectedLanguages,
    ...rest
}) => {
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
    const state = useAppState();
    const [filter, setFilter] = useState('');
    const [wordCount, setWordCount] = useState(0);

    const fetchSelectedTranslation = async (selectedId) => {
        if (translatedLanguage) {
            setIsTranslateChange(true);
            setCurrentLanguage(selectedId);
            setCurrentLanguageObject(await fetchLexigoLanguage(selectedId));
            setTranslationSingleSelected(2);
            if (translatedLanguage.filter((e) => e.language_id == selectedId).length > 0) {
                setLanguageText(translatedLanguage.filter((e) => e.language_id == selectedId)[0].language.language);
            }
            if (user) {
                const newTranslatedLanguage = await fetchTranslatedLanguage(id, user.signature);
                setLanguageText(
                    newTranslatedLanguage.filter(
                        (e) => e.language_id == selectedLanguages[selectedLanguages.length - 1],
                    )[0].language.language,
                );
                await fetchTranslate(selectedId);
                setTranslationSingleSelected(selectedId);
                setSelectedLanguages([]);
            }
        }
    };

    const openPricingPage = async () => {
        if (!config.hideTranslateModal) {
            setTranslateModal(true);
        } else {
            let totalDuration = await getTotalDurationRequest(user.id, rest.projectData.file.workspace_id);

            const translationVideoDuration = parseFloat(rest.projectData.file.duration) * selectedLanguages.length;
            totalDuration = translationVideoDuration + parseFloat(user.totalDuration);
            const maxDuration = access.userPackage.length;
            if (totalDuration > maxDuration) {
                rest.setDurationExceedModal(true);
            } else {
                rest.setShowPricingModal(true);
            }
        }
    };

    const languageOnChange = async (lang_id) => {
        if (selectedLanguages.indexOf(lang_id) == -1) {
            setSelectedLanguages([...selectedLanguages, lang_id]);
        } else {
            setSelectedLanguages(selectedLanguages.filter((e) => e !== lang_id));
        }
    };

    const countWords = () => {
        let wordsCount = 0;
        if (props.id == null) return 0;

        state.subtitle.map((sub) => {
            if (sub.text.length > 0) {
                wordsCount += sub.text.split(' ').length;
            }
        });
        return wordsCount;
    };

    useEffect(() => {
        setWordCount(countWords());
    }, [state.subtitle]);

    const props = {
        config,
        user,
        id,
        selectedLanguages,
        setConfig,
        translateModal,
        createTranslation,
        fetchTranslated,
        setTranslateState,
        setPlaying,
        setTranslateModal,
        setIsTranslation,
        setLoading,
        translatedLanguage,
        setLanguageText,
        setTranslationSingleSelected,
        setCurrentLanguageObject,
        setCurrentLanguage,
        setIsTranslateChange,
        pricing,
        languages,
        setPricing,
        access,
        setSelectedLanguages,
        ...rest,
    };
    return props.user.email !== 'admin@lote.co' ? (
            <div className={`custom-dropdown  inline-block mr-0-translate-mobile ${isButton ? 'mr-3' : ''}`} ref={ref}>
                <TranslateModal {...props} />
                <DurationExceedModal {...props} />
                <PricingModal {...props} />
                {isButton ? (
                    <button
                        className={`dropdown-toggle space-between btn bg-white text-theme-lexigo rounded-3px w-full font-bold`}
                        aria-expanded="false"
                        style={{
                            height: '32.7px',
                        }}
                        onClick={() => {
                            setFilter('');
                            setIsComponentVisible(!isComponentVisible);
                        }}
                    >
                        Translate <ChevronDown className="w-4 h-4 ml-2" />
                    </button>
                ) : (
                    <Tooltip
                        title={
                            state.subtitle.length > 0 && wordCount > 0
                                ? 'Translate'
                                : 'Please add subtitles in order to start a translation.'
                        }
                        placement="right"
                        arrow
                    >
                        <span>
                            <TranslateIcon
                                className="w-6 h-6"
                                onClick={() => {
                                    if (state.subtitle.length > 0 && wordCount > 0) {
                                        setFilter('');
                                        if (state.subtitle.length > 0) {
                                            setIsComponentVisible(!isComponentVisible);
                                        }
                                    }
                                }}
                            />
                        </span>
                    </Tooltip>
                )}

                <div
                    className={`translate-dropdown custom-dropdown-menu dropdown-menu-translation-tab w-56 ${
                        isComponentVisible ? 'show' : ''
                    }`}
                >
                    <div className="custom-dropdown-menu__content box bg-control-dark">
                        <div className="container-language py-4 p-5">
                            {/* <label>Create translation</label> */}
                            <input
                                type="text"
                                className="form-control input-dark"
                                style={{ fontFamily: 'GorditaRegular' }}
                                placeholder="Search language..."
                                value={filter}
                                onChange={(e) => setFilter(e.target.value)}
                            />

                            {languages.map((language, i) => {
                                if (language.language.toLowerCase().indexOf(filter.toLowerCase()) >= 0) {
                                    return (
                                        <div
                                            key={language.id}
                                            className={`form-check text-white mt-2 pl-1 ${
                                                translatedLanguage &&
                                                translatedLanguage.filter((x) => x.language.id === language.id).length > 0
                                                    ? 'hidden'
                                                    : ''
                                            }`}
                                        >
                                            <input
                                                id={`lang-${language.id}`}
                                                className={`form-check-input`}
                                                type="checkbox"
                                                value={language.id}
                                                checked={
                                                    selectedLanguages.filter((x) => x === language.id).length > 0
                                                        ? true
                                                        : false
                                                }
                                                onChange={() => {}}
                                                onClick={(e) => {
                                                    languageOnChange(parseInt(language.id));
                                                }}
                                            />
                                            <label
                                                className="form-check-label"
                                                onClick={(e) => {
                                                    languageOnChange(parseInt(language.id));
                                                }}
                                            >
                                                {language.language}
                                            </label>
                                        </div>
                                    );
                                }
                            })}
                        </div>

                        <div className="flex items-center mt-3">
                            <button
                                id="translate-button"
                                data-dismiss="dropdown"
                                onClick={async () => {
                                    setIsComponentVisible(!isComponentVisible);
                                    openPricingPage();
                                }}
                                className={`btn btn-lexigo w-full text-white font-bold ${
                                    selectedLanguages.length > 0 && state.subtitle.length > 0 ? '' : 'btn-lexigo-disabled'
                                }`}
                                title={
                                    selectedLanguages.length > 0 && state.subtitle.length > 0
                                        ? ''
                                        : 'Please add subtitles in order to start a translation.'
                                }
                                style={{
                                    border: 'none',
                                    borderRadius: '0 0 3px 3px',
                                }}
                                disabled={selectedLanguages.length > 0 && state.subtitle.length > 0 ? false : true}
                            >
                                Translate ({selectedLanguages.length})
                            </button>
                        </div>
                    </div>
                </div>
            </div>
    ) : null;
};

export default CreateTranslationDropdown;
