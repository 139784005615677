import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { File } from 'react-feather';
import { useAppState } from '../../overmind/index.js';
import CreateTranslationDropdown from '../CreateTranslationDropdown.js';
import DownloadIcon from '../Icon/DownloadIcon.js';

const ToolbarLeft = (props) => {
    const state = useAppState();

    return (
        <div className="toolbar-left hide-for-mobile">
            <Tooltip title="Subtitle" placement="right" arrow>
                <div className="toolbar-left-item active">
                    <File className="w-6 h-6" />
                </div>
            </Tooltip>
            {/*<CreateVoiceOverButton*/}
            {/*    config={props.config}*/}
            {/*    setConfig={props.setConfig}*/}
            {/*    translateState={props.translateState}*/}
            {/*    languageText={props.languageText}*/}
            {/*    projectData={props.projectData}*/}
            {/*    user={props.user}*/}
            {/*    id={props.id}*/}
            {/*    currentLanguage={props.currentLanguage}*/}
            {/*/>*/}
            {/*<div className="toolbar-left-item">*/}
            {/*    <Folder className="w-6 h-6"/>*/}
            {/*</div>*/}
            {props.access && props.access.accessMapping.role.toLowerCase() !== 'owner' ? (
                ''
            ) : props.user.email !== 'admin@lote.co' ? (
                <div className="toolbar-left-item">
                    <CreateTranslationDropdown {...props} />
                </div>
            ) : null}
            <Tooltip title="Download" placement="right" arrow>
                <div className="toolbar-left-item">
                    <DownloadIcon className="w-6 h-6" onClick={() => props.setDownloadModal(true)} />
                </div>
            </Tooltip>
        </div>
    );
};

export default ToolbarLeft;
