/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-script-url */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { Breadcrumbs, Link } from '@material-ui/core';
import Tooltip from '@mui/material/Tooltip';
import DT from 'duration-time-conversion';
import $ from 'jquery';
import { json } from 'overmind';
import { createRef, useCallback, useEffect, useRef, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { BarChart2, Check, ChevronRight, ChevronUp, Film, Folder, Globe, Layout } from 'react-feather';
import { hotjar } from 'react-hotjar';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';
import 'react-tippy/dist/tippy.css';
import styled from 'styled-components';
import useDynamicRefs from 'use-dynamic-refs';
import AudioDownloadModal from '../components/Audio/AudioDownloadModal';
import AudioPlayerControl from '../components/Audio/AudioPlayerControl';
import AudioPlayer from '../components/AudioPlayer';
import BreadcrumbsMore from '../components/Breadcrumbs/BreadcrumbsMore';
import BreadcrumbsTranslation from '../components/Breadcrumbs/BreadcrumbsTranslation';
import StudioSubtitleCard from '../components/Card/StudioSubtitleCard';
import StudioTranslationCard from '../components/Card/StudioTranslationCard';
import EyeIcon from '../components/Icon/EyeIcon.js';
import ShareIcon from '../components/Icon/ShareIcon.js';
import MuteAll from '../components/MuteAllButton';
import MobileModalNav from '../components/Navbar/MobileModalNav';
import NewFooter from '../components/NewFooter';
import PlainTextEditor from '../components/PlainTextEditor';
import ShareUserIcon from '../components/ShareIcon/ShareUserIcon';
import ShareModal from '../components/ShareModal';
import SwitchCustom from '../components/SwitchCustom';
import LoadTemplateModal from '../components/Template/LoadTemplateModal';
import SaveTemplateModal from '../components/Template/SaveTemplateModal';
import ThreeDotsLoader from '../components/ThreeDotsLoader';
import ToolbarLeft from '../components/Toolbar/ToolbarLeft.js';
import ToolbarRightAudio from '../components/Toolbar/ToolbarRightAudio.js';
import TranslationPlainTextEditor from '../components/TranslationPlainTextEditor';
import CreateVoiceOverButton from '../components/VoiceOver/CreateVoiceOverButton';
import VolumeSetting from '../components/Volume/VolumeSetting';
import Sub from '../libs/Sub';
import { useActions, useAppState, useReaction } from '../overmind';
import { getAccessDetails, getUserBySignatureAndSubtitleId } from '../requests/AccessRequest';
import { exportAudioTranscription } from '../requests/AudioRequest';
import { fetchFontFamilies } from '../requests/FontRequest';
import { fetchLanguages, fetchLexigoLanguage } from '../requests/LanguageRequest';
import { fetchSpeakers } from '../requests/SpeakerRequest';
import {
    createSubtitle,
    fetchSubtitleData,
    fetchSubtitleStyle,
    removeSubtitle,
    saveSubtitle,
} from '../requests/SubtitleRequest';
import { getTemplate } from '../requests/TemplateRequest';
import { fetchTranslatedLanguage, fetchTranslation, fetchTranslationHeader } from '../requests/TranslateRequest';
import { getVoiceOver } from '../requests/VoiceOverRequest';
import { getVolume, updateVolume } from '../requests/VolumeRequest';
import { getUserBySignature } from '../requests/auth';
import { getKeyCode, sleep } from '../utils';
import TranslationEditor from './TranslationEditor';

const Style = styled.div`
    height: 100vh;
    position: relative;
    .main {
        display: flex;
        height: calc(100% - 200px);

        .player {
            flex: 1;
        }
    }

    .footer {
        height: 30%;
    }
`;

export default function AudioStudio({ location }) {
    const $audio = createRef();
    const $miniAudio = createRef();
    const history = useHistory();
    const reaction = useReaction();
    const [durationExceedModal, setDurationExceedModal] = useState(false);
    const actions = useActions();
    const subtitleHistory = useRef([]);
    const translationHistory = useRef([]);
    const notificationSystem = useRef(null);
    const [translateModal, setTranslateModal] = useState(false);
    const [pricingLoading, setPricingLoading] = useState(false);
    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const [loaded, setLoaded] = useState(false); //after load subtitle, for not saving in first load
    const [config, setConfig] = useState(null);
    const [pricing, setPricing] = useState({
        add_dtp_price: 0,
        add_priority_price: 0,
        not_supported_language: [],
        package_plus_price: 0,
        package_plus_price_per_word: 0,
        package_prime_price: 0,
        package_prime_price_per_word: 0,
        package_pro_price: 0,
        package_pro_price_per_word: 0,
        package_pure_price: 0,
        package_pure_price_per_word: 0,
        plus_time: '-',
        prime_time: '-',
        pro_time: '-',
        pure_time: '-',
        naati_per_word: '',
        add_naati_price: '',
    });

    const [translationSingleDropdown, setTranslationSingleDropdown] = useState(false);
    const [player, setPlayer] = useState(null);
    const [miniPlayer, setMiniPlayer] = useState(null);
    const [subtitleUpdate, setSubtitleUpdate] = useState(false);
    const [processing, setProcessing] = useState(0);
    const [language, setLanguage] = useState(null);
    const [languages, setLanguages] = useState([]);
    const [id, setId] = useState(null);
    const [user, setUser] = useState(null);
    const [projectData, setProjectData] = useState(null);
    const [waveform, setWaveform] = useState(null);
    const [playing, setPlaying] = useState(false);
    const [miniPlaying, setMiniPlaying] = useState(false);
    const [playTime, setPlayTime] = useState(0);
    const [duration, setDuration] = useState(8);
    const [audioTime, setAudioTime] = useState(0);
    const [downloadModal, setDownloadModal] = useState(false);
    const [saveTemplateModal, setSaveTemplateModal] = useState(false);
    const [loadTemplateModal, setLoadTemplateModal] = useState(false);
    const [isTranslation, setIsTranslation] = useState(null); //open translation page
    const [translateState, setTranslateState] = useState(false); //is translate render to video
    const [tab, setTab] = useState('card');
    const [currentLanguage, setCurrentLanguage] = useState(null); // id bahasa
    const [currentLanguageObject, setCurrentLanguageObject] = useState(null); // id bahasa
    const [translatedLanguage, setTranslatedLanguage] = useState(null); // list translated language
    // const [translation, setTranslationOriginal] = useState([]); //translation object
    const [isTranslationUpdate, setIsTranslationUpdate] = useState(false); // translation flag
    const [languageText, setLanguageText] = useState('Choose language'); //text only bahasa terpilih
    const [isSubtitleLoaded, setIsSubtitleLoaded] = useState(false);
    const [isTranslateChange, setIsTranslateChange] = useState(false);
    const [translationHeader, setTranslationheader] = useState(null);
    const [translationLoading, setTranslationLoading] = useState(false);
    const [activeTranslationDetailString, setActiveTranslationDetailString] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const [openShareModal, setOpenShareModal] = useState(false);
    const [access, setAccess] = useState(null);
    const [segmentIndex, setSegmentIndex] = useState(null); //untuk highlight
    const [isSubtitleAddedOrDeleted, setIsSubtitleAddedOrDeleted] = useState(false);
    // const segmentRef = useRef();
    const [translationSegmentRef, setTranslationSegmentRef] = useDynamicRefs();
    const [showPricingModal, setShowPricingModal] = useState(false);
    const [showPricingInfoModal, setShowPricingInfoModal] = useState(false);
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [fullscreen, setFullscreen] = useState(false);
    const [template, setTemplate] = useState([]);
    const [isShared, setIsShared] = useState(false);
    const [audioLoaded, setAudioLoaded] = useState(false);
    const [voiceOverTimer, setVoiceOverTimer] = useState(null);

    const state = useAppState();
    const newSub = useCallback((item) => new Sub(item), []);
    const newTranslate = useCallback((item) => new Sub(item), []);
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    const isOriginalLocked = json(state.accessDetails).map((access) => {
        return access.details.filter((x) => x.language_id == -1).length;
    });
    const hasSub = useCallback(
        (sub) => {
            let index = state.subtitle.indexOf(sub);
            if (index == -1) {
                const data = [...JSON.parse(JSON.stringify(json(state.subtitle)))];
                index = data.map((a) => a.id).indexOf(sub.id);
            }
            return index;
        },
        [state.subtitle],
    );
    const hasTranslation = useCallback(
        (translate) => {
            let index = state.translation.indexOf(translate);
            if (index == -1) {
                const data = [...JSON.parse(JSON.stringify(json(state.translation)))];
                index = data.map((a) => a.id).indexOf(translate.id);
            }
            return index;
        },
        [state.translation],
    );

    useEffect(async () => {
        if (user) {
            setLanguages(await fetchLanguages(user.signature, id));
        }
    }, [user]);

    const formatSub = useCallback(
        (sub) => {
            if (Array.isArray(sub)) {
                return sub.map((item) => newSub(item));
            }
            return newSub(sub);
        },
        [newSub],
    );

    const formatTranslation = useCallback(
        (translate) => {
            if (Array.isArray(translate)) {
                return translate.map((item) => newTranslate(item));
            }
            return newTranslate(translate);
        },
        [newTranslate],
    );
    const copySubs = useCallback(() => formatSub(state.subtitle), [state.subtitle, formatSub]);
    const copyTranslation = useCallback(
        () => formatTranslation(state.translation),
        [state.translation, formatTranslation],
    );

    const undoSubs = useCallback(() => {
        const subs = subtitleHistory.current.pop();
        if (subs) {
            actions.setSubtitle(subs);
        }
    }, [subtitleHistory]);

    const clearSubs = useCallback(() => {
        actions.setSubtitle([]);
        subtitleHistory.current.length = 0;
    }, [subtitleHistory]);

    const checkSub = useCallback(
        (sub) => {
            sub = new Sub(sub);
            const index = hasSub(sub);
            if (index < 0) return;
            const previous = state.subtitle[index - 1];
            return (previous && sub.startTime < previous.endTime) || !sub.check;
        },
        [state.subtitle, hasSub],
    );

    const checkTranslation = useCallback(
        (translate) => {
            const index = hasTranslation(translate);
            if (index < 0) return;
            const previous = state.translation[index - 1];
            return (previous && translate.startTime < previous.endTime) || !translate.check;
        },
        [state.translation, hasTranslation],
    );

    const notify = useCallback(
        (obj) => {
            // https://github.com/igorprado/react-notification-system
            const notification = notificationSystem.current;
            notification.clearNotifications();
            notification.addNotification({
                position: 'tc',
                dismissible: 'none',
                autoDismiss: 2,
                message: obj.message,
                level: obj.level,
            });
        },
        [notificationSystem],
    );

    const removeTranslation = useCallback(
        (translate) => {
            const index = hasTranslation(translate);
            if (index < 0) return;
            const subs = copyTranslation();
            subs.splice(index, 1);
            actions.setTranslation([]);
            actions.setTranslation(subs);
            setIsTranslationUpdate(true);
        },
        [hasTranslation, copyTranslation],
    );

    const removeSub = useCallback(
        async (sub) => {
            setSubtitleUpdate(true);
            const index = hasSub(sub);
            if (index < 0) return;
            await removeSubtitle(state.subtitle[index]);
            const subs = copySubs();
            subs.splice(index, 1);
            actions.setSubtitle(subs);
            setIsSubtitleAddedOrDeleted(true);
        },
        [hasSub, copySubs],
    );

    const addSub = useCallback(
        (index, sub) => {
            const subs = copySubs();
            const newSub = formatSub(sub);
            subs.splice(index, 0, newSub);
            actions.setSubtitle(subs);
            setIsSubtitleAddedOrDeleted(true);
        },
        [copySubs, formatSub],
    );

    const updateSub = useCallback(
        (sub, obj) => {
            setSubtitleUpdate(true);
            const index = hasSub(sub);
            if (index < 0) return;
            const subs = copySubs();
            const subClone = formatSub(sub);
            Object.assign(subClone, obj);
            if (subClone.check) {
                subs[index] = subClone;
                actions.setSubtitle(subs);
            }
        },
        [hasSub, copySubs, formatSub],
    );
    const updateTranslation = useCallback(
        (translate, obj) => {
            setIsTranslationUpdate(true);
            const index = hasTranslation(translate);
            if (index < 0) return;
            const subs = copyTranslation();
            const subClone = formatTranslation(translate);
            Object.assign(subClone, obj);
            if (subClone.check) {
                subs[index] = subClone;
                actions.setTranslation(subs);
            }
            setIsTranslationUpdate(false);
        },
        [hasTranslation, copyTranslation, formatTranslation],
    );

    const mergeSub = useCallback(
        (sub) => {
            const index = hasSub(sub);
            if (index < 0) return;
            const subs = copySubs();
            const next = subs[index + 1];
            if (!next) return;
            const merge = newSub({
                start: sub.start,
                end: next.end,
                text: sub.text.trim() + '\n' + next.text.trim(),
            });
            subs[index] = merge;
            subs.splice(index + 1, 1);
            actions.setSubtitle(subs);
        },
        [hasSub, copySubs, newSub],
    );

    const splitSub = useCallback(
        (sub, start) => {
            const index = hasSub(sub);
            if (index < 0 || !sub.text || !start) return;
            const subs = copySubs();
            const text1 = sub.text.slice(0, start).trim();
            const text2 = sub.text.slice(start).trim();
            if (!text1 || !text2) return;
            const splitDuration = (sub.duration * (start / sub.text.length)).toFixed(3);
            if (splitDuration < 0.2 || sub.duration - splitDuration < 0.2) return;
            subs.splice(index, 1);
            const middleTime = DT.d2t(sub.startTime + parseFloat(splitDuration));
            subs.splice(
                index,
                0,
                newSub({
                    start: sub.start,
                    end: middleTime,
                    text: text1,
                }),
            );
            subs.splice(
                index + 1,
                0,
                newSub({
                    start: middleTime,
                    end: sub.end,
                    text: text2,
                }),
            );
            actions.setSubtitle(subs);
        },
        [hasSub, copySubs, newSub],
    );

    const onKeyDown = useCallback(
        (event) => {
            const keyCode = getKeyCode(event);
            switch (keyCode) {
                case 90:
                    event.preventDefault();
                    if (event.metaKey) {
                        undoSubs();
                    }
                    break;
                default:
                    break;
            }
        },
        [player, playing, undoSubs],
    );

    const fetchTemplate = async () => {
        setTemplate((await getTemplate(props.user.signature)).data);
    };
    useEffect(() => {
        if (props.user) {
            fetchTemplate();
        }
    }, [user]);

    useEffect(() => {
        window.addEventListener('keydown', onKeyDown);
        return () => window.removeEventListener('keydown', onKeyDown);
    }, [onKeyDown]);

    const fetchTranslate = async (languageId) => {
        if (languageId == null && currentLanguage == null) return;
        else if (languageId == null && currentLanguage != null) languageId = currentLanguage;
        actions.setTranslation([]);
        actions.setSkeletonLoading(true);
        actions.setWorkbenchDetailId(null);
        setTranslationLoading(true);

        const newConfig = await fetchSubtitleStyle(id, config.ratio, currentLanguage);

        setProjectData((prevData) => ({
            ...prevData,
            watermark_url: newConfig.watermark_url,
            cover: newConfig.cover,
            cover_url: newConfig.cover_url,
        }));

        setConfig({
            ...config,
            bold: newConfig.style.bold === 1,
            underline: newConfig.style.underline === 1,
            italic: newConfig.style.italic === 1,
            align: newConfig.style.text_align,
            position: newConfig.style.subtitle_position.position,
            fontColor: newConfig.style.font_color,
            backgroundColor: newConfig.style.background_color,
            effect: newConfig.style.effect,
            fontSize: newConfig.style.font_size,
            fontFamily: newConfig.style.font,
            watermarkX: newConfig.style.watermark_x,
            watermarkY: newConfig.style.watermark_y,
            watermarkFileName: newConfig.style.watermark_file_name,
            watermarkOpacity: newConfig.style.watermark_opacity,
            heading: newConfig.style.heading,
            coverFileName: newConfig.cover?.file_name,
            heading_bold: newConfig.style.heading_bold === 1,
            heading_underline: newConfig.style.heading_underline === 1,
            heading_italic: newConfig.style.heading_italic === 1,
            heading_align: newConfig.style.heading_text_align,
            heading_position: newConfig.style.heading_subtitle_position.position,
            heading_fontColor: newConfig.style.heading_font_color,
            heading_backgroundColor: newConfig.style.heading_background_color,
            heading_effect: newConfig.style.heading_effect,
            heading_fontSize: newConfig.style.heading_font_size,
            heading_fontFamily: newConfig.style.heading_font,
            hideTranslateModal: newConfig.header.hide_translate_modal,
            hideVoiceOverModal: newConfig.header.hide_voice_over_modal,
            headingTime: newConfig.style.heading_time,
            customHeadingTime: newConfig.style.heading_time > 0 ? true : false,
            marginV: newConfig.style.marginV,
            heading_pos_x: newConfig.style.heading_pos_x,
            heading_pos_y: newConfig.style.heading_pos_y,
            box_highlight: newConfig.style.box_highlight,
            box_highlight_color: newConfig.style.box_highlight_color|| '#ffffff'
        });

        const data = await fetchTranslation(id, languageId);
        const newData = formatTranslation(data);
        actions.setTranslation(newData);
        const translateHeader = await fetchTranslationHeader(id, languageId);
        setTranslationheader(translateHeader);
        setTranslationLoading(false);
        actions.setSkeletonLoading(false);
    };

    const [translationTimer, setTranslationTimer] = useState(null);
    useEffect(() => {
        if (translationHeader && translationHeader.locked) {
            if (translationTimer != null) clearInterval(translationTimer);
            const timer = setInterval(async () => {
                const translateHeader = await fetchTranslationHeader(id, translationHeader.language_id);
                if (!translateHeader.locked) {
                    clearInterval(timer);
                    setTranslationheader(translateHeader);
                    const data = await fetchTranslation(id, translationHeader.language_id);
                    const newData = formatTranslation(data);
                    actions.setTranslation(newData);
                }
            }, 10000);
            setTranslationTimer(timer);
        }
    }, [translationHeader]);

    useEffect(() => {
        if (isTranslateChange) {
            fetchTranslate();
            setIsTranslateChange(false);
        }
    }, [fetchTranslate, isTranslateChange]);

    useEffect(async () => {
        if (isSubtitleAddedOrDeleted && id && user) {
            //saving done
            await fetchTranslate();
            setIsSubtitleAddedOrDeleted(false);
        }
    }, [isSubtitleAddedOrDeleted]);

    useEffect(async () => {
        saveSub(isSubtitleLoaded);
    }, [state.subtitle, config, id, isSubtitleLoaded]);

    const saveSub = async (isSubtitleLoaded) => {
        if (isSubtitleLoaded) {
            setIsSaving(true);
            setLoaded(true);
            if (translateState) {
                await saveSubtitle(id, config, currentLanguage);
            } else {
                await saveSubtitle(id, config);
            }
            if (subtitleUpdate) {
                // fetchTranslate();
                setSubtitleUpdate(false);
            }
            setIsSaving(false);
        }
    };

    const fetchSubtitle = async (id) => {
        if (id == null) return;
        actions.setVoiceOverLoading(true);
        const data = await fetchSubtitleData(id);
        unstable_batchedUpdates(() => {
            setProjectData(data);

            setConfig({
                bold: data.style.bold === 1,
                underline: data.style.underline === 1,
                italic: data.style.italic === 1,
                align: data.style.text_align,
                position: data.style.subtitle_position.position,
                fontColor: data.style.font_color,
                backgroundColor: data.style.background_color,
                effect: data.style.effect,
                fontSize: data.style.font_size,
                fontFamily: data.style.font,
                watermarkX: data.style.watermark_x,
                watermarkY: data.style.watermark_y,
                watermarkFileName: data.style.watermark_file_name,
                coverFileName: data.cover?.file_name,
                watermarkOpacity: data.style.watermark_opacity,
                heading: data.style.heading,
                ratio: data.style.ratio,
                heading_bold: data.style.heading_bold === 1,
                heading_underline: data.style.heading_underline === 1,
                heading_italic: data.style.heading_italic === 1,
                heading_align: data.style.heading_text_align,
                heading_position: data.style.heading_subtitle_position.position,
                heading_fontColor: data.style.heading_font_color,
                heading_backgroundColor: data.style.heading_background_color,
                heading_effect: data.style.heading_effect,
                heading_fontSize: data.style.heading_font_size,
                heading_fontFamily: data.style.heading_font,
                hideTranslateModal: data.header.hide_translate_modal,
                hideVoiceOverModal: data.header.hide_voice_over_modal,
                headingTime: data.style.heading_time,
                customHeadingTime: data.style.heading_time > 0 ? true : false,
                marginV: data.style.marginV,
                heading_pos_x: data.style.heading_pos_x,
                heading_pos_y: data.style.heading_pos_y,
                box_highlight: data.style.box_highlight,
                box_highlight_color: data.style.box_highlight_color|| '#ffffff'
            });
            setIsSubtitleLoaded(true);
        });
    };
    const [map, setMap] = useState([]);
    const [speakers, setSpeakers] = useState([]);
    const getSpeakers = useCallback(async () => {
        setSpeakers(await fetchSpeakers(id));
    });

    useEffect(() => {
        let isSubscribed = true;
        if (id != null) {
            if (isSubscribed) {
                // fetchSubtitle(id);
                getSpeakers();
                triggerLoadVoiceOver();
            }
        }
        return () => (isSubscribed = false);
    }, [id, translateState, currentLanguage]);

    const triggerLoadVoiceOver = async () => {
        actions.setVoiceOverRequest(true);
        actions.setVoiceOverLoaded(false);
        // actions.setVoiceOverLoading(true);
        actions.setVoiceOverWaveform(null);
        actions.setOriginalMusicWaveform(null);
        actions.setOriginalVoiceWaveform(null);
        actions.setOriginalWaveform(null);
        actions.setVoiceOverWaveformLoaded(false);
        actions.setOriginalVoiceWaveformLoaded(false);
        actions.setOriginalMusicWaveformLoaded(false);
        setPlaying(false);
        let voiceOverAudio = json(state.voiceOverAudio);
        let originalMusic = json(state.originalMusic);
        let originalVocal = json(state.originalVocal);
        if (player) {
            player.pause();
            player.currentTime = 0;
        }
        if (voiceOverAudio !== null && originalMusic !== null && originalVocal !== null) {
            voiceOverAudio.pause();
            originalMusic.pause();
            originalVocal.pause();
            voiceOverAudio.currentTime = 0;
            originalVocal.currentTime = 0;
            originalMusic.currentTime = 0;
            // actions.setVoiceOverVolume(100);
            // actions.setOriginalMusicVolume(100);
            // actions.setOriginalVocalVolume(100);
            voiceOverAudio.volume = 1;
            originalVocal.volume = 1;
            originalMusic.volume = 1;
            actions.setVoiceOverAudio(null);
        }
        loadVoiceOver();
    };

    const loadVoiceOver = async () => {
        let reference_id = id;
        if (translateState) {
            const translateHeader = await fetchTranslationHeader(id, currentLanguage);
            reference_id = translateHeader.id;
        }
        // const timer = setInterval(async () => {
        try {
            // actions.setVoiceOverLoaded(false);
            const voiceOverData = await getVoiceOver(reference_id, translateState);
            actions.setVoiceOverRequest(false);

            if (voiceOverData.success) {
                if (voiceOverData.data.status === 'valid') {
                    actions.setVoiceOver(voiceOverData.data);
                    actions.setWaveformLoaded(false);
                }
            } else {
                if (!state.voiceOver && (state.waveformLoaded || isMobile)) {
                    // if already no voice over

                    actions.setVoiceOverLoading(false);
                    loadVolume();
                }

                // actions.setVoiceOver(null);
                actions.setOriginalMusic(null);
                actions.setOriginalVocal(null);
                actions.setVoiceOverAudio(null);
                actions.setVoiceOver(null);
                if (player) {
                    player.currentTime = 0;
                }
            }
            // clearInterval(timer);
        } catch (e) {
            console.log(e);
        }
        // }, 4000);
        // setVoiceOverTimer(timer);
    };

    const loadedVoiceOver = () => {
        if (json(state.voiceOverAudio)) {
            json(state.voiceOverAudio).removeEventListener('canplaythrough', () => {});
        }
        actions.setVoiceOverLoaded(true);
    };

    const loadedOriginalVocal = () => {
        actions.setOriginalVocalLoaded(true);
    };

    const loadedOriginalMusic = () => {
        actions.setOriginalMusicLoaded(true);
    };

    const loadVolume = async () => {
        let volumeConfig = null;
        if (translateState && translationHeader) {
            volumeConfig = (await getVolume(translationHeader.id, 1)).data;
        } else {
            volumeConfig = (await getVolume(id, 0)).data;
        }
        if (!volumeConfig) return;
        if (state.voiceOverAudio) {
            actions.setVoiceOverVolume(volumeConfig.voice_over_volume);
            state.voiceOverAudio.volume = parseFloat(volumeConfig.voice_over_volume / 100);
        }
        if (state.originalMusic) {
            actions.setOriginalMusicVolume(volumeConfig.original_music_volume);
            state.originalMusic.volume = parseFloat(volumeConfig.original_music_volume / 100);
        }
        if (state.originalVocal) {
            actions.setOriginalVocalVolume(volumeConfig.original_vocal_volume);
            state.originalVocal.volume = parseFloat(volumeConfig.original_vocal_volume / 100);
        }
        if (player) {
            actions.setVolume(volumeConfig.volume);
            player.volume = parseFloat(volumeConfig.volume / 100);
        }
    };

    useEffect(() => {
        if (!state.voiceOver && !state.voiceOverRequest && (state.waveformLoaded || isMobile)) {
            actions.setVoiceOverLoading(false);
            loadVolume();
        }
    }, [state.voiceOver, state.waveformLoaded]);

    useEffect(async () => {
        if (
            state.voiceOver &&
            !state.voiceOverRequest &&
            state.originalMusicLoaded &&
            state.originalVocalLoaded &&
            state.voiceOverLoaded &&
            ((state.originalMusicWaveformLoaded &&
                state.originalVoiceWaveformLoaded &&
                state.voiceOverWaveformLoaded) ||
                isMobile)
        ) {
            await sleep(5000);
            loadVolume();
            actions.setVoiceOverLoading(false);
        }
    }, [
        state.voiceOver,
        state.originalMusicLoaded,
        state.originalVocalLoaded,
        state.voiceOverLoaded,
        state.originalMusicWaveformLoaded,
        state.originalVoiceWaveformLoaded,
        state.voiceOverWaveformLoaded,
    ]);

    useEffect(() => {
        const voiceOverData = json(state.voiceOver);
        if (voiceOverData && voiceOverData.url !== undefined) {
            if (player) {
                player.pause();
                player.currentTime = 0;
            }
            const voiceOverAudio = new Audio(voiceOverData.url);
            voiceOverAudio.addEventListener('canplaythrough', loadedVoiceOver);
            actions.setVoiceOverAudio(voiceOverAudio);
            if (json(state.originalMusic) == null && json(state.originalVocal) == null) {
                const originalMusic = new Audio(voiceOverData.originalMusicUrl);
                const originalVocal = new Audio(voiceOverData.originalVocalUrl);
                originalMusic.addEventListener('canplaythrough', loadedOriginalMusic);
                originalVocal.addEventListener('canplaythrough', loadedOriginalVocal);
                actions.setOriginalMusic(originalMusic);
                actions.setOriginalVocal(originalVocal);
            }
        }
    }, [state.voiceOver]);

    useEffect(() => {
        if (miniPlayer) {
            const voiceOverAudio = json(state.voiceOverAudio);
            const originalMusic = json(state.originalMusic);
            const originalVocal = json(state.originalVocal);
            if (miniPlaying) {
                if (!state.voiceOverLoading) {
                    miniPlayer.play();

                    if (voiceOverAudio !== null && originalMusic !== null && originalVocal !== null) {
                        miniPlayer.muted = true;
                        voiceOverAudio.play();
                        originalMusic.play();
                        originalVocal.play();
                    }
                }
            } else {
                if (!state.voiceOverLoading) {
                    miniPlayer.pause();
                    if (voiceOverAudio !== null && originalMusic !== null && originalVocal !== null) {
                        voiceOverAudio.pause();
                        originalMusic.pause();
                        originalVocal.pause();
                    }
                }
            }
        }
    }, [miniPlayer, miniPlaying, state.voiceOverLoading]);

    useEffect(() => {
        if (player) {
            const wavesurferCursor = json(state.wavesurferCursor);
            const voiceOverAudio = json(state.voiceOverAudio);
            const originalMusic = json(state.originalMusic);
            const originalVocal = json(state.originalVocal);
            if (playing) {
                if (!state.voiceOverLoading) {
                    player.play();
                    if(wavesurferCursor && wavesurferCursor.current)wavesurferCursor.current.play();
                }
                if (voiceOverAudio !== null && originalMusic !== null && originalVocal !== null) {
                    voiceOverAudio.play();
                    originalMusic.play();
                    originalVocal.play();
                }
            } else {
                if (!state.voiceOverLoading) {
                    player.pause();
                    if(wavesurferCursor && wavesurferCursor.current)wavesurferCursor.current.pause();
                }
                if (voiceOverAudio !== null && originalMusic !== null && originalVocal !== null) {
                    voiceOverAudio.pause();
                    originalMusic.pause();
                    originalVocal.pause();
                }
            }
        }
    }, [player, playing, state.voiceOverLoading, state.wavesurferCursor]);

    useEffect(() => {
        try {
            const localSubtitle = projectData.details;
            actions.setSubtitle(localSubtitle.map((item) => new Sub(item)));
            setMap(
                localSubtitle.map((item) => {
                    return {
                        start: item.start,
                        end: item.end,
                    };
                }),
            );
        } catch (error) {}
    }, [projectData]);

    const fetchTranslated = async (projectId, signature) => {
        const data = await fetchTranslatedLanguage(projectId, signature);
        setTranslatedLanguage(data);
        return data;
    };

    const fetchUser = async (signature) => {
        const data = await getUserBySignature(signature);
        if (data.status) {
            setUser(data.data);
        }
        return data;
    };

    const exportSubtitle = () => {
        exportAudioTranscription(id);
    };

    const fetchAccess = async (signature, subtitleId) => {
        const data = await getUserBySignatureAndSubtitleId(subtitleId, signature);
        if (data.status) {
            setAccess(data.data);
        }
        return data;
    };

    const setupByUrlData = (query) => {
        const translationState = query.get('t');
        setIsTranslation(translationState);
        // isTranslation
    };

    const fetchFonts = async (signature, id) => {
        const fontFamiliesData = await fetchFontFamilies(`${signature}!${id}`);
        for await (const font of fontFamiliesData) {
            if (font.font_path == null) continue;
            let custom_font = new FontFace(
                font.font_name,
                `url(${process.env.REACT_APP_BACKEND_URL}/font/download/${font.id})`,
            );
            await custom_font
                .load()
                .then(function (loaded_face) {
                    document.fonts.add(loaded_face);
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    };

    const initData = async () => {
        // setLoading(true);
        // document.body.style.background = 'rgb(0 0 0 / 90%)';
        const query = new URLSearchParams(props.location.search);
        const projectId = query.get('id');
        const signature = query.get('u');
        const shared = query.get('shared');
        hotjar.initialize(3378103, 6);
        //fetch subtitle
        const [projectData, user, access, templateRequest, languages, translated, accessDetails] = await Promise.all([
            fetchSubtitleData(projectId),
            fetchUser(signature),
            fetchAccess(signature, projectId),
            getTemplate(signature),
            fetchLanguages(signature, id),
            fetchTranslated(projectId, signature, true),
            getAccessDetails(projectId, signature),
        ]);
        const translationId = query.get('tid') || null;
        const translationState = query.get('t') || false;
        if (translationState != false && translationId != null) {
            unstable_batchedUpdates(async () => {
                setTranslateState(true);
                setIsTranslation(translationState);
                setTranslationSingleSelected(translationId);
                setCurrentLanguage(translationId);
                if (translated.filter((e) => e.language_id == translationId).length > 0) {
                    setLanguageText(translated.filter((e) => e.language_id == translationId)[0].language.language);
                }
                setCurrentLanguageObject(await fetchLexigoLanguage(translationId));
            });
        }
        // await fetchSubtitle(projectId);
        setTranslationSingleSelected(translationId);
        // const projectData = await fetchSubtitleData(projectId);

        //fetch user
        // const user = await fetchUser(signature);

        //fetch access
        // const access = await fetchAccess(signature, projectId);
        //fetch template
        const template = templateRequest.data; //(await getTemplate(signature)).data;
        //fetch translated
        // const languages = await fetchLanguages(user.signature, id);
        // const translated = await fetchTranslated(projectId, signature, true);
        unstable_batchedUpdates(() => {
            setIsShared(shared);
            actions.setAccessDetails(accessDetails);
            setId(projectId);
            actions.setVoiceOverLoading(true);
            setProjectData(projectData);
            const subtitleDetails = projectData.details;
            actions.setSubtitle(subtitleDetails.map((item) => new Sub(item)));
            setConfig({
                bold: projectData.style.bold === 1,
                underline: projectData.style.underline === 1,
                italic: projectData.style.italic === 1,
                align: projectData.style.text_align,
                position: projectData.style.subtitle_position.position,
                fontColor: projectData.style.font_color,
                backgroundColor: projectData.style.background_color,
                effect: projectData.style.effect,
                fontSize: projectData.style.font_size,
                fontFamily: projectData.style.font,
                watermarkX: projectData.style.watermark_x,
                watermarkY: projectData.style.watermark_y,
                watermarkFileName: projectData.style.watermark_file_name,
                watermarkOpacity: projectData.style.watermark_opacity,
                coverFileName: projectData.cover?.file_name,
                cover: projectData.cover,
                heading: projectData.style.heading,
                ratio: projectData.style.ratio,
                heading_bold: projectData.style.heading_bold === 1,
                heading_underline: projectData.style.heading_underline === 1,
                heading_italic: projectData.style.heading_italic === 1,
                heading_align: projectData.style.heading_text_align,
                heading_position: projectData.style.heading_subtitle_position.position,
                heading_fontColor: projectData.style.heading_font_color,
                heading_backgroundColor: projectData.style.heading_background_color,
                heading_effect: projectData.style.heading_effect,
                heading_fontSize: projectData.style.heading_font_size,
                heading_fontFamily: projectData.style.heading_font,
                hideTranslateModal: projectData.header.hide_translate_modal,
                hideVoiceOverModal: projectData.header.hide_voice_over_modal,
                headingTime: projectData.style.heading_time,
                customHeadingTime: projectData.style.heading_time > 0 ? true : false,
                marginV: projectData.style.marginV,
                heading_pos_x: projectData.style.heading_pos_x,
                heading_pos_y: projectData.style.heading_pos_y,
                box_highlight: projectData.style.box_highlight,
                box_highlight_color: projectData.style.box_highlight_color|| '#ffffff'
            });
            setTemplate(template);
            setIsSubtitleLoaded(true);
            if (user.status) {
                setUser(user.data);
            }
            setLanguages(languages);
            if (access.status) {
                setAccess(access.data);
            }
            setTranslatedLanguage(translated);
        });

        // await Promise.all([
        //     fetchSubtitle(projectId),
        //     fetchUser(signature),
        //     fetchAccess(signature, projectId),
        //     fetchTranslated(projectId, signature),
        //     fetchFonts(signature, projectId),
        // ]);
        document.addEventListener('fullscreenchange', exitHandler);
        document.addEventListener('webkitfullscreenchange', exitHandler);
        document.addEventListener('mozfullscreenchange', exitHandler);
        document.addEventListener('MSFullscreenChange', exitHandler);

        await fetchFonts(signature, projectId);
        function exitHandler() {
            if (
                !document.fullscreenElement &&
                !document.webkitIsFullScreen &&
                !document.mozFullScreen &&
                !document.msFullscreenElement
            ) {
                setFullscreen(false);
            }
        }
    };

    useEffect(async () => {
        // setLoading(true);
        // document.body.style.background = 'rgb(0 0 0 / 90%)';
        initData();
        // const query = new URLSearchParams(props.location.search);
        // const projectId = query.get('id');
        // const signature = query.get('u');
        // const shared = query.get('shared');
        // setIsShared(shared);
        // hotjar.initialize(3378103, 6);
        // // console.log(hotjar.identify('USER_ID', { userProperty: 'value' }));
        // setId(projectId);
        // await Promise.all([
        //     fetchSubtitle(projectId),
        //     fetchUser(signature),
        //     fetchAccess(signature, projectId),
        //     fetchTranslated(projectId, signature),
        //     fetchFonts(signature, projectId),
        // ]);
        // const translatedLanguage = await fetchTranslated(projectId, signature);

        // const translationState = query.get('t') || false;
        // // setIsTranslation(translationState);
        // const translationId = query.get('tid') || null;
        // if (translationState != false && translationId != null) {
        //     unstable_batchedUpdates(async () => {
        //         setTranslateState(true);
        //         setIsTranslation(translationState);
        //         setTranslationSingleSelected(translationId);
        //         setCurrentLanguage(translationId);
        //         if (translatedLanguage.filter((e) => e.language_id == translationId).length > 0) {
        //             setLanguageText(
        //                 translatedLanguage.filter((e) => e.language_id == translationId)[0].language.language,
        //             );
        //         }
        //         setCurrentLanguageObject(await fetchLexigoLanguage(translationId));
        //     });
        // }
        // setTranslationSingleSelected(translationId);
        // document.addEventListener('fullscreenchange', exitHandler);
        // document.addEventListener('webkitfullscreenchange', exitHandler);
        // document.addEventListener('mozfullscreenchange', exitHandler);
        // document.addEventListener('MSFullscreenChange', exitHandler);

        // function exitHandler() {
        //     if (
        //         !document.fullscreenElement &&
        //         !document.webkitIsFullScreen &&
        //         !document.mozFullScreen &&
        //         !document.msFullscreenElement
        //     ) {
        //         setFullscreen(false);
        //     }
        // }
        // //
    }, []);

    useEffect(async () => {
        if (audioLoaded && translateState && id) {
            fetchTranslate(translationSingleSelected);
        }
    }, [audioLoaded, isTranslation, id]);

    const [translationSingleSelected, setTranslationSingleSelected] = useState(0);

    const addSubtitle = async (selectedIndex) => {
        try {
            let currId = state.currentIndex;
            if (selectedIndex !== null) {
                currId = selectedIndex;
            }
            let currentSub = state.subtitle[currId];

            let nextSub = state.subtitle[0];
            let start = DT.d2t(0);
            if (currentSub) {
                nextSub = state.subtitle[currId + 1];
                start = DT.d2t(DT.t2d(currentSub.end.slice(0, 12)) + 0.001);
            }
            let end;
            if (nextSub === undefined) {
                end = DT.d2t(DT.t2d(currentSub.end.slice(0, 12)) + 1);
            } else {
                end = DT.d2t(DT.t2d(nextSub.start.slice(0, 12)) - 0.001);
            }
            if (end < start) {
                end = start;
            }
            const createSubReq = await createSubtitle(id, {
                start,
                end,
                text: '',
            });
            addSub(
                currId + 1,
                newSub({
                    id: createSubReq.id,
                    start,
                    end,
                    text: '',
                }),
            );
        } catch (e) {}
    };

    const addSubtitleWithoutIndex = async () => {
        const start = DT.d2t(DT.t2d('00:00:00'));
        const end = DT.d2t(DT.t2d('00:00:01'));
        const createSubReq = await createSubtitle(id, {
            start,
            end,
            text: '',
        });
        addSub(
            0,
            newSub({
                id: createSubReq.id,
                start,
                end,
                text: '',
            }),
        );
    };

    const changeDuration = (isMinus) => {
        if (isMinus) {
            if (duration > 10) {
                setDuration(duration - 10);
            } else if (duration > 5) {
                setDuration(duration - 8);
            }
        } else {
            if (duration < 10) {
                setDuration(10);
            } else if (duration < 100) {
                setDuration(duration + 10);
            }
        }
    };

    useEffect(() => {
        if (waveform) {
            waveform.setOptions({
                duration: duration,
            });
        }
    }, [waveform, duration]);

    const playPauseAction = () => {
        if (!state.voiceOverLoading) {
            setSegmentIndex(null);
            if (isTranslation) {
                setMiniPlaying(!miniPlaying);
            } else {
                setPlaying(!playing);
            }
        }
    };

    const backward = () => {
        player.currentTime = player.currentTime - 5;
        // miniPlayer.currentTime = miniPlayer.currentTime - 5;
    };
    const reset = () => {
        player.currentTime = 0;
        let scrollableContainer = document.getElementById('subtitles');
        if (scrollableContainer) {
            try {
                scrollableContainer.scrollTo({
                    top: 0,
                    behavior: 'smooth', //auto, smooth, initial, inherit
                });
            } catch (e) {
                console.log(e);
            }
        }
        // miniPlayer.currentTime = miniPlayer.currentTime - 5;
    };

    const fastForward = () => {
        player.currentTime = player.currentTime + 5;
        // miniPlayer.currentTime = miniPlayer.currentTime - 5;
    };

    $('#translate-button').on('click', function () {
        $('#show-translate-button').trigger('click');
    });

    const toggleFullscreen = () => {
        let el = document.getElementById('video-container');
        if (!fullscreen) {
            if (el.requestFullscreen) {
                el.requestFullscreen();
            } else if (el.msRequestFullscreen) {
                el.msRequestFullscreen();
            } else if (el.mozRequestFullScreen) {
                el.mozRequestFullScreen();
            } else if (el.webkitRequestFullscreen) {
                el.webkitRequestFullscreen();
            }
        } else if (document.fullscreenElement) {
            document.exitFullscreen();
        }
        setFullscreen(!fullscreen);
    };

    const scrollRef = useRef();
    const listRef = useRef();
    const commitVolume = async (event, newValue) => {
        let is_translation = false;
        if (translateState) {
            is_translation = true;
        }
        await updateVolume({
            id: translateState ? translationHeader.id : id,
            is_translation: is_translation,
            volume: newValue,
            original_music_volume: state.originalMusicVolume,
            original_vocal_volume: state.originalVocalVolume,
            voice_over_volume: state.voiceOverVolume,
        });
    };

    const commitOriginalMusicVolume = async (event, newValue) => {
        let is_translation = false;
        if (translateState) {
            is_translation = true;
        }
        await updateVolume({
            id: translateState ? translationHeader.id : id,
            is_translation: is_translation,
            volume: state.volume,
            original_music_volume: newValue,
            original_vocal_volume: state.originalVocalVolume,
            voice_over_volume: state.voiceOverVolume,
        });
    };

    const commitOriginalVocalVolume = async (event, newValue) => {
        let is_translation = false;
        if (translateState) {
            is_translation = true;
        }
        await updateVolume({
            id: translateState ? translationHeader.id : id,
            is_translation: is_translation,
            volume: state.volume,
            original_music_volume: state.originalMusicVolume,
            original_vocal_volume: newValue,
            voice_over_volume: state.voiceOverVolume,
        });
    };

    const commitVoiceOverVolume = async (event, newValue) => {
        let is_translation = false;
        if (translateState) {
            is_translation = true;
        }
        await updateVolume({
            id: translateState ? translationHeader.id : id,
            is_translation: is_translation,
            volume: state.volume,
            original_music_volume: state.originalMusicVolume,
            original_vocal_volume: state.originalVocalVolume,
            voice_over_volume: newValue,
        });
    };

    const props = {
        isOriginalLocked,
        isShared,
        listRef,
        $miniAudio,
        hasTranslation,
        fetchSubtitle,
        selectedLanguages,
        addSubtitleWithoutIndex,
        setSelectedLanguages,
        durationExceedModal,
        setDurationExceedModal,
        commitVolume,
        commitOriginalMusicVolume,
        commitOriginalVocalVolume,
        commitVoiceOverVolume,
        setAudioLoaded,
        scrollRef,
        setSpeakers,
        speakers,
        languages,
        audioTime,
        setAudioTime,
        playTime,
        setPlayTime,
        $audio,
        pricing,
        setPricing,
        pricingLoading,
        setPricingLoading,
        template,
        setTemplate,
        fetchTemplate,
        saveTemplateModal,
        setSaveTemplateModal,
        loadTemplateModal,
        setLoadTemplateModal,
        translationHeader,
        // segmentRef,
        translationLoading,
        setTranslationLoading,
        translationSingleSelected,
        setTranslationSingleSelected,
        translationSingleDropdown,
        setTranslationSingleDropdown,
        isTranslateChange,
        setIsTranslateChange,
        fetchTranslated,
        location,
        id,
        setId,
        config,
        setConfig,
        player,
        setPlayer,
        miniPlayer,
        setMiniPlayer,
        projectData,
        waveform,
        setWaveform,
        playing,
        setPlaying,
        language,
        setLanguage,
        setProcessing,
        subtitleHistory,
        notify,
        newSub,
        hasSub,
        checkSub,
        removeSub,
        addSub,
        undoSubs,
        clearSubs,
        updateSub,
        formatSub,
        mergeSub,
        splitSub,
        duration,
        setProjectData,
        downloadModal,
        setDownloadModal,
        isTranslation,
        setIsTranslation,
        tab,
        setTab,
        translateModal,
        setTranslateModal,
        currentLanguage,
        setCurrentLanguage,
        translatedLanguage,
        setTranslatedLanguage,
        updateTranslation,
        removeTranslation,
        checkTranslation,
        formatTranslation,
        setIsTranslationUpdate,
        translateState,
        setTranslateState,
        miniPlaying,
        setMiniPlaying,
        languageText,
        setLanguageText,
        fetchTranslate,
        setIsSaving,
        isSaving,
        subtitleUpdate,
        currentLanguageObject,
        setCurrentLanguageObject,
        setOpenShareModal,
        openShareModal,
        user,
        setUser,
        access,
        setAccess,
        activeTranslationDetailString,
        setActiveTranslationDetailString,
        addSubtitle,
        segmentIndex,
        setSegmentIndex,
        translationSegmentRef,
        setTranslationSegmentRef,
        showPricingModal,
        setShowPricingModal,
        showPricingInfoModal,
        setShowPricingInfoModal,
        showPaymentModal,
        setShowPaymentModal,
        reset,
        backward,
        playPauseAction,
        fastForward,
        toggleFullscreen,
        fullscreen,
        setFullscreen,
        saveSub,
    };
    const [plainText, setPlainText] = useState(false);
    return (
        <Style>
            <AudioDownloadModal
                downloadModal={downloadModal}
                setDownloadModal={setDownloadModal}
                id={id}
                translatedLanguage={translatedLanguage}
                projectData={projectData}
                user={user}
                config={config}
            />
            <ShareModal {...props} />
            <SaveTemplateModal {...props} />
            <LoadTemplateModal {...props} />
            {state.loading ? (
                <div
                    className="col-span-12 flex flex-col justify-end items-center h-full"
                    style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                        marginTop: 'auto',
                        overflow: 'hidden',
                        marginBottom: 'auto',
                    }}
                >
                    <div
                        className="col-span-12 flex flex-col justify-end items-center h-full"
                        style={{ justifyContent: 'center', marginLeft: '3rem' }}
                    >
                        <i className="w-32 h-32">
                            <ThreeDotsLoader />
                        </i>
                    </div>
                </div>
            ) : (
                ''
            )}
            {/* {state.voiceOverLoading ? <TransparentLoading /> : ''} */}
            {user === null || access === null || config === null ? (
                <div
                    className="col-span-12 flex flex-col justify-end items-center h-full"
                    style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                        marginTop: 'auto',
                        overflow: 'hidden',
                        marginBottom: 'auto',
                        zIndex: 999999,
                    }}
                >
                    <div
                        className="col-span-12 flex flex-col justify-end items-center h-full"
                        style={{ justifyContent: 'center', marginLeft: '3rem' }}
                    >
                        <i className="w-32 h-32">
                            <ThreeDotsLoader />
                        </i>
                    </div>
                </div>
            ) : (
                <>
                    {isTranslation ? <TranslationEditor {...props} type="audio" /> : ''}
                    <div className="flex flex-col bg-dark h-full">
                        <header
                            style={{
                                overflow: 'hidden',
                                paddingTop: '1.5rem',
                                paddingBottom: '1.5rem',
                            }}
                            className="px-5 py-3 bg-navbar flex items-center justify-between mb-0 pl-0mobile navbar font-gordita"
                        >
                            <div className="flex items-center">
                                <button
                                    onClick={() => {
                                        window.location.href = process.env.REACT_APP_BACKEND_URL.replace(
                                            '/api',
                                            '/projects',
                                        );
                                    }}
                                    className="btn btn-back-mobile font-bold rounded-3px p-0 hide-check-for-desktop"
                                >
                                    <ChevronUp className="rotate-90" />
                                </button>
                                <img className="w-80px mr-3" src="./SCRIBE-logo 1.png" />

                                {isShared == '1' ? (
                                    <div className="flex items-center">
                                        <Globe
                                            stroke="#17BDB7"
                                            className="w-4 h-4"
                                            style={{
                                                display: 'inline',
                                            }}
                                        />

                                        <BreadcrumbsTranslation {...props} />
                                    </div>
                                ) : (
                                    <Breadcrumbs
                                        className="btn-save-hide-mobile"
                                        separator={<ChevronRight className="w-4 h-4" stroke="#8F93AA" />}
                                        aria-label="breadcrumb"
                                    >
                                        <Link
                                            underline="hover"
                                            key="1"
                                            target="_blank"
                                            color="inherit"
                                            href={`${process.env.REACT_APP_BACKEND_URL.replace('/api', '/projects')}`}
                                        >
                                            <Layout className="w-4 h-4" stroke="#8F93AA" />
                                        </Link>
                                        {projectData &&
                                        projectData.breadcrumbs_data &&
                                        projectData.breadcrumbs_data.length >= 1 ? (
                                            projectData.breadcrumbs_data.length > 1 ? (
                                                <BreadcrumbsMore projectData={projectData} />
                                            ) : (
                                                <Link
                                                    underline="hover"
                                                    target="_blank"
                                                    key="1"
                                                    color="inherit"
                                                    href={`${
                                                        process.env.REACT_APP_BACKEND_URL.replace('/api', '/folders') +
                                                        '?id=' +
                                                        projectData.breadcrumbs_data[0].id
                                                    }`}
                                                >
                                                    <Folder className="w-4 h-4" stroke="#8F93AA" />
                                                </Link>
                                            )
                                        ) : (
                                            ''
                                        )}

                                        <span className="cursor-pointer flex items-center">
                                            <Film className="w-4 h-4" stroke="#17BDB7" />{' '}
                                            <span
                                                style={{
                                                    fontSize: '.8rem',
                                                    marginLeft: '0.25rem',
                                                    display: 'inline-block',
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    maxWidth: '100px',
                                                    textOverflow: 'ellipsis',
                                                }}
                                                className="font-gordita"
                                            >
                                                {projectData ? projectData.file.file_name : ''}
                                            </span>
                                        </span>

                                        <div className="flex items-center">
                                            <Globe
                                                stroke="#17BDB7"
                                                className="w-4 h-4"
                                                style={{
                                                    display: 'inline',
                                                }}
                                            />

                                            <BreadcrumbsTranslation {...props} />
                                        </div>
                                    </Breadcrumbs>
                                )}
                            </div>
                            <div className="flex items-center hide-for-mobile">
                                {(access &&
                                    access.accessMapping.role.toLowerCase() !== 'owner' &&
                                    access.accessMapping.role.toLowerCase() !== 'editor') ||
                                (isShared == 1 &&
                                    translateState == false &&
                                    isOriginalLocked.length > 0 &&
                                    isOriginalLocked[0] === 0) ? (
                                    ''
                                ) : !isSaving ? (
                                    <button
                                        disabled
                                        className="btn btn-transparent font-bold mr-3 rounded-3px btn-save-hide-mobile"
                                    >
                                        All changes saved
                                    </button>
                                ) : (
                                    <button
                                        onClick={() => {
                                            props.saveSub(isSubtitleLoaded);
                                        }}
                                        className="btn btn-transparent active font-bold mr-3 rounded-3px btn-save-hide-mobile"
                                    >
                                        Saving...
                                    </button>
                                )}

                                {access && access.accessMapping.role.toLowerCase() !== 'owner' ? (
                                    ''
                                ) : (
                                    <>
                                        <div className="flex items-center gap-2 mr-3">
                                            {/* user access, max 4 background colors */}
                                            <ShareUserIcon {...props} />
                                        </div>
                                        <Tooltip title="Share" placement="left" arrow>
                                            <button
                                                onClick={() => {
                                                    setOpenShareModal(true);
                                                }}
                                                className={props.user.email === 'admin@lote.co' ? `d-none` : `btn btn-lexigo-green w-10 h-10 font-bold rounded-full p-0 flex items-center justify-center`}
                                            >
                                                <ShareIcon
                                                    className="text-white"
                                                    style={{ width: '22px', height: '22px' }}
                                                />
                                            </button>
                                        </Tooltip>
                                    </>
                                )}
                            </div>
                            <div className="ml-auto hide-for-desktop flex items-center">
                                {(access &&
                                    access.accessMapping.role.toLowerCase() !== 'owner' &&
                                    access.accessMapping.role.toLowerCase() !== 'editor') ||
                                (isShared == 1 &&
                                    translateState == false &&
                                    isOriginalLocked.length > 0 &&
                                    isOriginalLocked[0] === 0) ? (
                                    ''
                                ) : !isSaving ? (
                                    <button
                                        disabled
                                        className="btn btn-lexigo-green text-white font-bold mr-3 rounded-full p-6px btn-save-hide-desktop"
                                    >
                                        <Check className="w-5 h-5" />
                                    </button>
                                ) : (
                                    ''
                                )}
                                <a
                                    href="javascript:;"
                                    id="menu-mobile-click"
                                    onClick={() => actions.setOpenMobileNav(true)}
                                >
                                    <BarChart2 className="rotate-90" />
                                </a>
                                <MobileModalNav {...props} isShared={isShared} />
                            </div>
                        </header>

                        <div className="flex content-video">
                            <ToolbarLeft {...props} />
                            <div className="flex w-full">
                                <div className={`flex-1 content-video-editor `}>
                                    <div className="flex lg:flex-row flex-col w-full h-full flex-lg-wrap">
                                        <div
                                            className={`order-2 lg:order-1 col-span-12 lg:w-1/4 flex-grow editor-sidebar mt-0 hide-fullscreen hide-voice-over
                                                ${state.voiceOverAudio ? '' : 'hide-voice-over'}
                                        `}
                                        >
                                            <div className="h-full">
                                                <div className="post intro-y overflow-hidden bg-sub-dark h-full rounded-3px shadow-none">
                                                    <div className="post__content tab-content">
                                                        <div className="flex flex-col h-full">
                                                            <div
                                                                className="plain-text-switch text-white"
                                                                style={{ padding: '0 2rem' }}
                                                            >
                                                                <div className="py-5 flex items-center justify-between">
                                                                    <p className="font-bold font-grostesk text-20px">
                                                                        Subtitles
                                                                    </p>
                                                                    <div className="switch-custom">
                                                                        <SwitchCustom tab={tab} setTab={setTab} />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="divider divider-dark m-0"></div>

                                                            <div
                                                                className={`tab-pane ${
                                                                    tab === 'card' ? 'active' : ''
                                                                } tab-content-side-scribe-3 px-2 py-4 overflow-auto`}
                                                                ref={scrollRef}
                                                                id="subtitles"
                                                            >
                                                                {!translateState ? (
                                                                    <div className="margin-top-0-mobile flex flex-col gap-2">
                                                                        <StudioSubtitleCard
                                                                            getSpeakers={getSpeakers}
                                                                            id={id}
                                                                            updateSub={updateSub}
                                                                            checkSub={checkSub}
                                                                            projectData={projectData}
                                                                            removeSub={removeSub}
                                                                            access={access}
                                                                            setIsSaving={setIsSaving}
                                                                            addSubtitle={addSubtitle}
                                                                            speakers={speakers}
                                                                            setSpeakers={setSpeakers}
                                                                            segmentIndex={segmentIndex}
                                                                            setSegmentIndex={setSegmentIndex}
                                                                            isTranslation={isTranslation}
                                                                            playing={playing}
                                                                            config={config}
                                                                            sourceQuery=""
                                                                            type="audio"
                                                                            {...props}
                                                                        />
                                                                    </div>
                                                                ) : translationHeader && translationHeader.locked ? (
                                                                    <div
                                                                        style={{
                                                                            marginTop: '1.25rem',
                                                                        }}
                                                                        className="box box-shadow-none rounded-3px pb-5 h-full flex flex-col"
                                                                    >
                                                                        <div className="flex-1">
                                                                            <div className="bg-gray-lexigo-2 w-full h-full flex justify-center items-center">
                                                                                <span>Translating...</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <div>
                                                                        <StudioTranslationCard
                                                                            getSpeakers={getSpeakers}
                                                                            {...props}
                                                                            targetQuery=""
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div
                                                                className={`tab-pane ${
                                                                    tab === 'text' ? 'active' : ''
                                                                } tab-content-side-scribe-3 px-5`}
                                                                aria-labelledby="plain-text-tab"
                                                                style={{ overflow: 'auto', height: '100%' }}
                                                                id="plain-text"
                                                            >
                                                                {/* <TranslationSingleDropdown {...props} /> */}
                                                                {/* <PlainTextEditor {...props} /> */}
                                                                {!translateState ? (
                                                                    <div
                                                                        style={{
                                                                            marginTop: isMobile ? 0 : '1.25rem',
                                                                            position: 'relative',
                                                                        }}
                                                                    >
                                                                        <PlainTextEditor {...props} />
                                                                    </div>
                                                                ) : (
                                                                    <div
                                                                        style={{
                                                                            marginTop: isMobile ? 0 : '1.25rem',
                                                                            position: 'relative',
                                                                        }}
                                                                    >
                                                                        <TranslationPlainTextEditor {...props} />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className={`order-1 lg:order-2 col-span-12 lg:w-3/4 editor editor-responsive flex flex-col hide-voice-over
                                                ${state.voiceOverAudio ? '' : 'hide-voice-over'}
                                        `}
                                        >
                                            <div className="flex h-full items-center">
                                                <div
                                                    className="video-content box mx-0 lg:mx-5 video-max-height box-video-custom h-full"
                                                    // style={{ width: 'calc(100% - 100px)' }}
                                                    id="video-container"
                                                >
                                                    <div
                                                        className={`flex h-full justify-center relative mx-auto w-full aspect-video`}
                                                        style={{ maxHeight: 'calc(100%)' }}
                                                    >
                                                        {/* {projectData && projectData.watermark_url.length <= 0 ? (
                                                            <AudioWaveform {...props} />
                                                        ) : (
                                                            <img
                                                                style={{ height: '30vh' }}
                                                                src={projectData.watermark_url}
                                                            />
                                                        )} */}
                                                        {projectData ? <AudioPlayer {...props} /> : ''}
                                                    </div>

                                                    {(access &&
                                                        access.accessMapping.role.toLowerCase() !== 'owner' &&
                                                        access.accessMapping.role.toLowerCase() !== 'editor') ||
                                                    (isShared == 1 &&
                                                        translateState == false &&
                                                        isOriginalLocked.length > 0 &&
                                                        isOriginalLocked[0] === 0) ? (
                                                        ''
                                                    ) : (
                                                        <ToolbarRightAudio {...props} />
                                                    )}
                                                </div>
                                            </div>
                                            {/* <Footer {...props} style={{ height: '200px' }} /> */}
                                        </div>

                                        <div
                                            className="flex flex-col order-2 lg:order-3 col-span-12 lg:w-1/4 lg:mt-control hide-fullscreen hide-for-mobile"
                                            style={{ height: '65px' }}
                                        >
                                            <div className="bg-control-dark rounded-3px px-5 py-2 flex items-center flex-grow  justify-between">
                                                <span className="text-white font-grostesk font-bold text-xl">
                                                    Volume Settings
                                                </span>
                                                <VolumeSetting
                                                    commitOriginalVocalVolume={commitOriginalVocalVolume}
                                                    commitOriginalMusicVolume={commitOriginalMusicVolume}
                                                    commitVoiceOverVolume={commitVoiceOverVolume}
                                                    commitVolume={commitVolume}
                                                    player={player}
                                                />
                                            </div>
                                        </div>

                                        <div className="order-1 lg:w-3/4 lg:order-3 relative w-full lg:pl-control lg:pr-control lg:mt-control player-control-fullscreen-overlay">
                                            <AudioPlayerControl {...props} />
                                        </div>

                                        <div className="order-1 lg:w-1/4 lg:order-4 relative w-full flex flex-col hide-fullscreen hide-for-mobile">
                                            <div
                                                className="bg-control-dark px-1 lg:mt-control flex gap-5px items-center rounded-3px"
                                                style={{ height: '40px' }}
                                            >
                                                <MuteAll {...props} />
                                                <CreateVoiceOverButton
                                                    config={config}
                                                    setConfig={setConfig}
                                                    translateState={translateState}
                                                    languageText={languageText}
                                                    projectData={projectData}
                                                    user={user}
                                                    id={id}
                                                    currentLanguage={currentLanguage}
                                                    {...props}
                                                />
                                            </div>
                                            <div className="bg-control-dark flex flex-col lg:mt-control flex-grow text-white rounded-3px">
                                                <div
                                                    className="border-b border-dark px-5 flex items-center text-content"
                                                    style={{ height: '100px' }}
                                                >
                                                    <EyeIcon className="mr-2 pointer w-6 h-6" />
                                                    Subtitles
                                                </div>

                                                {/* <StudioLeftVolume
                                                    commitOriginalVocalVolume={commitOriginalVocalVolume}
                                                    commitOriginalMusicVolume={commitOriginalMusicVolume}
                                                    commitVoiceOverVolume={commitVoiceOverVolume}
                                                    commitVolume={commitVolume}
                                                    player={player}
                                                /> */}
                                            </div>
                                        </div>

                                        <div className="order-1 lg:w-3/4 lg:order-4 relative w-full lg:pl-control lg:pr-control hide-fullscreen-timeline hide-for-mobile">
                                            {isMobile ? '' : <NewFooter {...props} type="audio" />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </Style>
    );
}
