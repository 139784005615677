/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react';
import { ChevronDown, Send } from 'react-feather';
import { useActions } from '../../overmind';
import { fetchLexigoLanguage } from '../../requests/LanguageRequest';
import { fetchSubtitleStyle } from '../../requests/SubtitleRequest';
import useComponentVisible from '../useComponentVisible';

const BreadcrumbsTranslation = ({
    config,
    setTranslateModal,
    setIsTranslation,
    id,
    translatedLanguage,
    fetchTranslated,
    setPlaying,
    setTranslateState,
    translateState,
    setCurrentLanguage,
    setTranslationSingleSelected,
    setCurrentLanguageObject,
    currentLanguageObject,
    languageText,
    setLanguageText,
    setProjectData,
    setConfig,
    translationSingleSelected,
    setTranslationSingleDropdown,
    setIsTranslateChange,
    projectData,
    user,
    access,
}) => {
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
    const actions = useActions();

    const openTranslationPage = async () => {
        setTranslateState(true);
        setIsTranslation(true);
        let url = new URL(window.location);
        url.searchParams.set('t', true);
        window.history.pushState(null, '', url.toString());
        await fetchTranslated(id, user.signature);
        setPlaying(false);
    };

    const [selectedLanguages, setSelectedLanguages] = useState([]);

    const fetchSelectedTranslation = async (selectedId) => {
        if (translatedLanguage) {
            setCurrentLanguage(selectedId);
            setCurrentLanguageObject(await fetchLexigoLanguage(selectedId));
            setTranslationSingleSelected(2);
            if (translatedLanguage.filter((e) => e.language_id == selectedId).length > 0) {
                setLanguageText(translatedLanguage.filter((e) => e.language_id == selectedId)[0].language.language);
            }
            let url = new URL(window.location);
            url.searchParams.set('tid', selectedId);
            window.history.pushState(null, '', url.toString());
            setTranslationSingleSelected(selectedId);
            setTimeout(() => {
                actions.setVoiceOverLoading(false);
            }, 1000);
        }
    };

    const handleLanguageClick = async (selectedId) => {
        if (selectedId == translationSingleSelected && translateState) {
            return;
        }
        setIsTranslateChange(true);
        fetchSelectedTranslation(selectedId);
        setTranslationSingleDropdown(false);
        setTranslateState(true);
        setIsComponentVisible(false);
    };

    const changeToDefaultLanguage = async () => {
        if (translateState) {
            setTranslateState(false);

            const newConfig = await fetchSubtitleStyle(id, config.ratio);

            setProjectData((prevData) => ({
                ...prevData,
                watermark_url: newConfig.watermark_url,
                cover: newConfig.cover,
                cover_url: newConfig.cover_url,
            }));
            setConfig({
                ...config,
                bold: newConfig.style.bold === 1,
                underline: newConfig.style.underline === 1,
                italic: newConfig.style.italic === 1,
                align: newConfig.style.text_align,
                position: newConfig.style.subtitle_position.position,
                fontColor: newConfig.style.font_color,
                backgroundColor: newConfig.style.background_color,
                effect: newConfig.style.effect,
                fontSize: newConfig.style.font_size,
                fontFamily: newConfig.style.font,
                watermarkX: newConfig.style.watermark_x,
                watermarkY: newConfig.style.watermark_y,
                watermarkFileName: newConfig.style.watermark_file_name,
                watermarkOpacity: newConfig.style.watermark_opacity,
                heading: newConfig.style.heading,
                coverFileName: newConfig.cover?.file_name,
                heading_bold: newConfig.style.heading_bold === 1,
                heading_underline: newConfig.style.heading_underline === 1,
                heading_italic: newConfig.style.heading_italic === 1,
                heading_align: newConfig.style.heading_text_align,
                heading_position: newConfig.style.heading_subtitle_position.position,
                heading_fontColor: newConfig.style.heading_font_color,
                heading_backgroundColor: newConfig.style.heading_background_color,
                heading_effect: newConfig.style.heading_effect,
                heading_fontSize: newConfig.style.heading_font_size,
                heading_fontFamily: newConfig.style.heading_font,
                hideTranslateModal: newConfig.header.hide_translate_modal,
                hideVoiceOverModal: newConfig.header.hide_voice_over_modal,
                headingTime: newConfig.style.heading_time,
                customHeadingTime: newConfig.style.heading_time > 0 ? true : false,
                marginV: newConfig.style.marginV,
                heading_pos_x: newConfig.style.heading_pos_x,
                heading_pos_y: newConfig.style.heading_pos_y,
                box_highlight: newConfig.style.box_highlight,
                box_highlight_color: newConfig.style.box_highlight_color,
            });
            setIsComponentVisible(!isComponentVisible);
            setTimeout(() => {
                actions.setVoiceOverLoading(false);
            }, 1000);
        }
    };

    return (
        <div
            style={{
                display: 'inline-block',
            }}
            className={`translation-dropdown-mobile translation-dropdown`}
        >
            <div className="custom-dropdown flex" ref={ref}>
                <button
                    style={{
                        borderWidth: '0',
                        boxShadow: 'none',
                        padding: '0',
                        marginLeft: '0.25rem',
                    }}
                    className={`dropdown-toggle btn rounded-3px font-gordita text-content`}
                    aria-expanded="false"
                    onClick={() => {
                        if (user.email !== 'admin@lote.co') {
                            setIsComponentVisible(!isComponentVisible);
                        }
                    }}
                >
                    <div
                        style={{
                            display: 'inline-block',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            fontSize: '.8rem',
                            fontWeight: '400',
                        }}
                    >
                        {!translateState ? projectData && projectData.file.language.language : languageText}{' '}
                    </div>
                    { user.email !== 'admin@lote.co' ? (
                        <ChevronDown className="w-4 h-4 ml-2" />
                    ) : (
                        <Send
                            className="w-4 h-4 ml-2"
                            color="#ffffff"
                            style={{
                                strokeWidth: 1,
                            }}
                            onClick={() => {
                                setIsComponentVisible(false);
                                openTranslationPage();
                                handleLanguageClick(currentLanguageObject.id);
                            }}
                        />
                    )}
                </button>

                <div
                    className={`custom-dropdown-menu dropdown-menu-translation-tab w-56 ${
                        isComponentVisible ? 'show' : ''
                    }`}
                    style={{}}
                >
                    <div className="custom-dropdown-menu__content box bg-control-dark">
                        <div className="container-language py-4 p-5">
                            <div className="form-check mt-2 pl-1" onClick={changeToDefaultLanguage}>
                                <input
                                    id="lang-1"
                                    className="form-check-input"
                                    type="checkbox"
                                    value="1"
                                    checked={translateState ? false : true}
                                    onChange={() => {}}
                                    onClick={changeToDefaultLanguage}
                                />
                                <label onClick={changeToDefaultLanguage} className="form-check-label">
                                    {projectData && projectData.file.language.language}
                                </label>
                            </div>

                            {translatedLanguage &&
                                translatedLanguage.map((data) => {
                                    return (
                                        <div
                                            key={'data-' + data.id}
                                            className="form-check mt-2 pl-1"
                                            onClick={() => handleLanguageClick(data.language.id)}
                                        >
                                            <input
                                                id={`checkbox-tr-${data.language.id}`}
                                                className="form-check-input"
                                                type="checkbox"
                                                value={data.language.id}
                                                checked={
                                                    translateState && translationSingleSelected == data.language.id
                                                        ? true
                                                        : false
                                                }
                                                onChange={() => {}}
                                                onClick={() => handleLanguageClick(data.language.id)}
                                            />
                                            <label
                                                className="form-check-label"
                                                onClick={() => handleLanguageClick(data.language.id)}
                                            >
                                                {data.language.language}
                                            </label>
                                            {access && access.accessMapping.role.toLowerCase() === 'viewer' ? (
                                                ''
                                            ) : (
                                                <Send
                                                    className="ml-auto"
                                                    color="#ffffff"
                                                    style={{
                                                        width: '0.65rem',
                                                        height: '0.65rem',
                                                        strokeWidth: 1,
                                                    }}
                                                    onClick={() => {
                                                        setIsComponentVisible(false);
                                                        openTranslationPage();
                                                        handleLanguageClick(data.language.id);
                                                    }}
                                                />
                                            )}
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BreadcrumbsTranslation;
