/* eslint-disable no-loop-func */
import { Modal } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { saveAs } from 'file-saver';
import { memo, useEffect, useState } from 'react';
import { X } from 'react-feather';
import { useAppState } from '../overmind';
import {
    getExportStatus,
    getExportedUrl,
    getFileData,
    getRenderPercentage,
    subtitleRender,
} from '../requests/SubtitleRequest';

const DownloadModal = memo(({ downloadModal, setDownloadModal, id, translatedLanguage, projectData, user, config }) => {
    const [srt, setSrt] = useState(false);
    const [vtt, setVtt] = useState(false);
    const [txt, setTxt] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [zipping, setZipping] = useState(false);
    const [burnSubtitle, setBurnSubtitle] = useState(true);
    const [sendEmail, setSendEmail] = useState(false);
    const [percentage, setPercentage] = useState(0);
    const [progress, setProgress] = useState(null);
    const [language, setLanguage] = useState(-1);
    const [ratio, setRatio] = useState([]);
    const [compression, setCompression] = useState('low');
    const [ratioError, setRatioError] = useState(false);
    const state = useAppState();

    useEffect(() => {
        if (config) {
            setRatio([config.ratio]);
        }
    }, [config]);

    useEffect(() => {
        if (ratio.length > 0) {
            setRatioError(false);
        }
    }, [ratio]);

    const proceedDownload = async () => {
        if (ratio.length <= 0) {
            setRatioError(true);
            return;
        }
        setPercentage(0);
        setIsFetching(true);
        const renderVideo = await subtitleRender(
            id,
            vtt,
            srt,
            txt,
            ratio,
            language,
            user.signature,
            compression,
            state.volume,
            state.voiceOverVolume,
            state.originalMusicVolume,
            state.originalVocalVolume,
            burnSubtitle,
            sendEmail
        );
        if (!renderVideo.success) {
            setIsFetching(false);
            return;
        }

        const statusCheck = setInterval(async () => {
            const status = await getExportStatus(id);
            const processCount = (language === -1 ? translatedLanguage.length + 1 : 1) * ratio.length;
            const exportPercentage = await getRenderPercentage(id, processCount);
            setProgress(Math.ceil(exportPercentage.data));

            if (status.data == 'done') {
                setZipping(true);
                clearInterval(statusCheck);
                const renderedUrl = (await getExportedUrl(id)).data;
                let url = renderedUrl.dropbox_url.replace('www.dropbox.com', 'dl.dropboxusercontent.com');
                await fetch(url)
                    .then(async (res) => {
                        const reader = res.body.getReader();
                        const contentLength = res.headers.get('Content-Length');
                        let receivedLength = 0; // received that many bytes at the moment
                        let chunks = []; // array of received binary chunks (comprises the body)
                        while (true) {
                            const { done, value } = await reader.read();

                            if (done) {
                                break;
                            }

                            chunks.push(value);
                            receivedLength += value.length;
                            let downloadPercent = Math.ceil((receivedLength / contentLength) * 100);
                            if (downloadPercent > 100) {
                                downloadPercent = 100;
                            }
                            setPercentage(downloadPercent);
                        }
                        let blob = new Blob(chunks);
                        setIsFetching(false);
                        setZipping(false);
                        setDownloadModal(false);
                        const fileData = await getFileData(id);
                        let filename = fileData['file_name'];
                        if (fileData['file_name'].substr(0, fileData['file_name'].lastIndexOf('.')) !== '') {
                            filename = fileData['file_name'].substr(0, fileData['file_name'].lastIndexOf('.')) + '.zip';
                        } else {
                            filename = filename + '.zip';
                        }
                        saveAs(blob, filename);
                    })
                    .catch((e) => {
                        console.log(e);
                    });
            }
        }, 5000);
    };

    const renderProcessPercentage = () => {
        if (zipping || !progress) return percentage + ' %';
        return <>{progress} %</>;
    };

    return (
        <Modal
            open={downloadModal}
            onClose={() => {
                setDownloadModal(false);
            }}
        >
            <div
                id="modal-download"
                className="modal show"
                style={{
                    marginTop: '0px',
                    marginLeft: '0px',
                    paddingLeft: '0px',
                    zIndex: 10000,
                }}
                data-backdrop="static"
                tabIndex="-1"
                aria-hidden="true"
                role="dialog"
            >
                <div className="modal-dialog">
                    <div className="modal-content h-full">
                        <div className="modal-header flex items-center justify-between">
                            <p className="modal-title font-bold">Download Video</p>
                            <a data-dismiss="modal" href="javascript:;" onClick={() => setDownloadModal(false)}>
                                <X className="w-5 h-5" />
                            </a>
                        </div>
                        <div className="modal-body h-full">
                            <div className={`${isFetching ? 'hidden' : ''}`}>
                                <div>
                                    <label for="" className="form-label font-bold text-title text-base rounded-3px">
                                        Language
                                    </label>
                                    <select
                                        name=""
                                        id="selDownloadLang"
                                        className="form-control"
                                        onChange={(e) => setLanguage(parseInt(e.target.value))}
                                    >
                                        <option value="-1">All Languages</option>
                                        <option value="0">{projectData && projectData.file.language.language}</option>
                                        {translatedLanguage &&
                                            translatedLanguage.map((data, i) => {
                                                return (
                                                    <option key={data.language.id} value={data.language.id}>
                                                        {data.language.language}
                                                    </option>
                                                );
                                            })}
                                    </select>
                                </div>
                                <div className="mt-5">
                                    <label for="" className="form-label font-bold text-title text-base rounded-3px">
                                        Ratio
                                    </label>
                                    {ratioError ? <p className="error-msg">Ratio must be selected!</p> : ''}

                                    <div className="flex flex-col sm:flex-row mt-2">
                                        <div className="form-check mr-3">
                                            <input
                                                id="checkbox-switch-4"
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={ratio.includes('16:9')}
                                                onChange={() => {
                                                    if (ratio.includes('16:9')) {
                                                        let newRatio = ratio.filter((e) => e !== '16:9');
                                                        setRatio(newRatio);
                                                    } else {
                                                        setRatio([...ratio, '16:9']);
                                                    }
                                                }}
                                            />
                                            <label
                                                className="form-check-label"
                                                for="checkbox-switch-4"
                                                onChange={() => {
                                                    if (ratio.includes('16:9')) {
                                                        let newRatio = ratio.filter((e) => e !== '16:9');
                                                        setRatio(newRatio);
                                                    } else {
                                                        setRatio([...ratio, '16:9']);
                                                    }
                                                }}
                                            >
                                                16:9
                                            </label>
                                            <i data-feather="alert-circle" className="w-4 h-4 ml-1"></i>
                                        </div>
                                        <div className="form-check mr-3 mt-2 sm:mt-0">
                                            <input
                                                id="checkbox-switch-5"
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={ratio.includes('9:16')}
                                                onChange={() => {
                                                    if (ratio.includes('9:16')) {
                                                        let newRatio = ratio.filter((e) => e !== '9:16');
                                                        setRatio(newRatio);
                                                    } else {
                                                        setRatio([...ratio, '9:16']);
                                                    }
                                                }}
                                            />{' '}
                                            <label
                                                className="form-check-label"
                                                for="checkbox-switch-5"
                                                onChange={() => {
                                                    if (ratio.includes('9:16')) {
                                                        let newRatio = ratio.filter((e) => e !== '9:16');
                                                        setRatio(newRatio);
                                                    } else {
                                                        setRatio([...ratio, '9:16']);
                                                    }
                                                }}
                                            >
                                                9:16
                                            </label>
                                            <i data-feather="alert-circle" className="w-4 h-4 ml-1"></i>
                                        </div>
                                        <div className="form-check mr-3 mt-2 sm:mt-0">
                                            <input
                                                id="checkbox-switch-6"
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={ratio.includes('4:5')}
                                                onChange={() => {
                                                    if (ratio.includes('4:5')) {
                                                        let newRatio = ratio.filter((e) => e !== '4:5');
                                                        setRatio(newRatio);
                                                    } else {
                                                        setRatio([...ratio, '4:5']);
                                                    }
                                                }}
                                            />{' '}
                                            <label
                                                className="form-check-label"
                                                for="checkbox-switch-6"
                                                onChange={() => {
                                                    if (ratio.includes('4:5')) {
                                                        let newRatio = ratio.filter((e) => e !== '4:5');
                                                        setRatio(newRatio);
                                                    } else {
                                                        setRatio([...ratio, '4:5']);
                                                    }
                                                }}
                                            >
                                                4:5
                                            </label>
                                            <i data-feather="alert-circle" className="w-4 h-4 ml-1"></i>
                                        </div>
                                        <div className="form-check mr-3 mt-2 sm:mt-0">
                                            <input
                                                id="checkbox-switch-7"
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={ratio.includes('1:1')}
                                                onChange={() => {
                                                    if (ratio.includes('1:1')) {
                                                        let newRatio = ratio.filter((e) => e !== '1:1');
                                                        setRatio(newRatio);
                                                    } else {
                                                        setRatio([...ratio, '1:1']);
                                                    }
                                                }}
                                            />{' '}
                                            <label
                                                className="form-check-label"
                                                for="checkbox-switch-7"
                                                onChange={() => {
                                                    if (ratio.includes('1:1')) {
                                                        let newRatio = ratio.filter((e) => e !== '1:1');
                                                        setRatio(newRatio);
                                                    } else {
                                                        setRatio([...ratio, '1:1']);
                                                    }
                                                }}
                                            >
                                                1:1
                                            </label>
                                            <i data-feather="alert-circle" className="w-4 h-4 ml-1"></i>
                                        </div>
                                    </div>
                                    {/* <select
                                        name=""
                                        id="selRatio"
                                        className="form-control"
                                        onChange={(e) => setRatio(e.target.value)}
                                    >
                                        <option value="16:9">16:9</option>
                                        <option value="16:9">9:16</option>
                                        <option value="4:5">4:5</option>
                                        <option value="1:1">1:1</option>
                                    </select> */}
                                </div>

                                <div className="mt-5">
                                    <label for="" className="form-label font-bold text-title text-base rounded-3px">
                                        Format
                                    </label>
                                    <div className="flex flex-col sm:flex-row mt-2">
                                        <div className="form-check mr-3">
                                            <input
                                                id="checkbox-switch-8"
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={srt}
                                                onChange={() => setSrt(!srt)}
                                            />
                                            <label
                                                className="form-check-label"
                                                for="checkbox-switch-8"
                                                onChange={() => setSrt(!srt)}
                                            >
                                                SRT
                                            </label>
                                            <i data-feather="alert-circle" className="w-4 h-4 ml-1"></i>
                                        </div>
                                        <div className="form-check mr-3 mt-2 sm:mt-0">
                                            <input
                                                id="checkbox-switch-9"
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={vtt}
                                                onChange={() => setVtt(!vtt)}
                                            />{' '}
                                            <label
                                                className="form-check-label"
                                                for="checkbox-switch-9"
                                                onChange={() => setVtt(!vtt)}
                                            >
                                                VTT
                                            </label>
                                            <i data-feather="alert-circle" className="w-4 h-4 ml-1"></i>
                                        </div>
                                        <div className="form-check mr-3 mt-2 sm:mt-0">
                                            <input
                                                id="checkbox-switch-10"
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={txt}
                                                onChange={() => setTxt(!txt)}
                                            />{' '}
                                            <label
                                                className="form-check-label"
                                                for="checkbox-switch-10"
                                                onChange={() => setTxt(!txt)}
                                            >
                                                TXT
                                            </label>
                                            <i data-feather="alert-circle" className="w-4 h-4 ml-1"></i>
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-5">
                                    <label for="" className="form-label font-bold text-title text-base rounded-3px">
                                        Render Subtitle
                                    </label>
                                    <div className="flex flex-col sm:flex-row mt-2">
                                        <div className="form-check mr-3">
                                            <input
                                                id="checkbox-switch-11"
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={burnSubtitle}
                                                onChange={() => setBurnSubtitle(!burnSubtitle)}
                                            />
                                            <label
                                                className="form-check-label"
                                                for="checkbox-switch-11"
                                                onChange={() => setBurnSubtitle(!burnSubtitle)}
                                            >
                                                Burned subtitles in the video
                                            </label>
                                            <i data-feather="alert-circle" className="w-4 h-4 ml-1"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5">
                                    <label
                                        for=""
                                        title="Higher compression means lower quality"
                                        className="form-label font-bold text-title text-base rounded-3px"
                                    >
                                        Video Quality
                                    </label>
                                    <select
                                        name=""
                                        id="selDownloadLang"
                                        className="form-control"
                                        onChange={(e) => setCompression(e.target.value)}
                                    >
                                        <option value="low">High</option>
                                        <option value="medium">Medium</option>
                                        <option value="high">Low</option>
                                    </select>
                                </div>
                                <div className={user?.email === 'admin@lote.co' ? 'd-none' : 'mt-5'}>
                                    <label for="" className="form-label font-bold text-title text-base rounded-3px">
                                        Download Notification
                                    </label>
                                    <div className="flex flex-col sm:flex-row mt-2">
                                        <div className="form-check mr-3">
                                            
                                            <input
                                                id="checkbox-switch-12"
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={sendEmail}
                                                onChange={() => setSendEmail(!sendEmail)}
                                            />
                                            <label
                                                className="form-check-label"
                                                for="checkbox-switch-12"
                                            >
                                                Notify me by email when my content is ready
                                            </label>
                                            <i data-feather="alert-circle" className="w-4 h-4 ml-1"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-10 ">
                                    <div className="flex items-center justify-center">
                                        {/* <button className="btn btn-outline-lexigo font-bold text-theme-lexigo mr-5 rounded-3px w-full">
                                            Save to Google Drive
                                        </button> */}
                                        <button
                                            className="btn btn-lexigo-soft-green text-white font-bold rounded-3px w-full"
                                            onClick={() => {
                                                proceedDownload();
                                            }}
                                        >
                                            Download
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className={`${!isFetching ? 'hidden' : ''}`}>
                                <div className="text-center">
                                    <div className="text-3xl font-bold">
                                        {zipping
                                            ? 'Downloading and compressing your video...'
                                            : 'Processing your video'}
                                    </div>
                                </div>
                                <div className="text-center mt-5">
                                    <div className="text-3xl font-bold">{renderProcessPercentage()}</div>
                                </div>
                                <div className="container-bar mt-5">
                                    <LinearProgress style={{ width: '100%', height: '12px', borderRadius: '10px' }} />
                                </div>
                                <div className="text-center mt-5 mb-3">
                                    <div>
                                        Depending on the size of the video and the download preferences you made, this
                                        process may take several minutes.
                                        <br />
                                        <br />
                                        You can close this window and we'll email you once the files are ready for you
                                        to download.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
});

export default DownloadModal;
