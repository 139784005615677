/* eslint-disable react-hooks/exhaustive-deps */

import { json } from 'overmind';
import React, { useState } from 'react';
import { RefreshCw } from 'react-feather';
import { useActions, useAppState } from '../../overmind';
import VoiceOverConfirmationModal from './VoiceOverConfirmationModal';
import VoiceOverModal from './VoiceOverModal';

const CreateVoiceOverButton = ({
    config,
    setConfig,
    translateState,
    languageText,
    projectData,
    user,
    id,
    access,
    currentLanguage,
    isOriginalLocked,
    isShared,
    ...props
}) => {
    const [voiceOverConfirmationModal, setVoiceOverConfirmationModal] = useState(false);
    const [voiceOverModal, setVoiceOverModal] = useState(false);
    const state = useAppState();
    const actions = useActions();

    const handleOnClick = () => {
        if (config.hideVoiceOverModal) {
            setVoiceOverModal(true);
        } else {
            setVoiceOverConfirmationModal(true);
        }
    };

    return (
        <>
            <VoiceOverConfirmationModal
                voiceOverConfirmationModal={voiceOverConfirmationModal}
                setVoiceOverModal={setVoiceOverModal}
                setVoiceOverConfirmationModal={setVoiceOverConfirmationModal}
                config={config}
                setConfig={setConfig}
            />
            <VoiceOverModal
                user={user}
                voiceOverModal={voiceOverModal}
                setVoiceOverModal={setVoiceOverModal}
                translateState={translateState}
                languageText={languageText}
                projectData={projectData}
                id={id}
                currentLanguage={currentLanguage}
                {...props}
            />
            <button
                className={user.email !== 'admin@lote.co' ? `btn btn-lexigo-green text-white py-1 lg:py-2 font-bold rounded-3px flex-1 text-content` : 'd-none'}
                onClick={handleOnClick}
                disabled={
                    json(state.voiceOverAudio) !== null ||
                    (access &&
                        access.accessMapping.role.toLowerCase() !== 'owner' &&
                        access.accessMapping.role.toLowerCase() !== 'editor') ||
                    (isShared == 1 &&
                        translateState == false &&
                        isOriginalLocked.length > 0 &&
                        isOriginalLocked[0] === 0)
                }
            >
                {state.voiceOverLoading
                    ? 'Loading...'
                    : json(state.voiceOverAudio) !== null
                    ? 'Voice over created'
                    : 'Create voice over'}
            </button>

            {!state.voiceOverSync ? (
                ''
            ) : (
                <div className="toolbar-left-item">
                    <RefreshCw onClick={handleOnClick} />
                </div>
            )}
        </>
    );
};

export default CreateVoiceOverButton;
